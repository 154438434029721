<template>
  <notifications position="bottom right" />
  <top-panel v-if="currentRouteName !== 'DailyReport'" />
  <div class="main-panel" v-if="!loginLoading">
    <loader v-if="pageLoading" />
    <left-panel v-if="currentRouteName !== 'DailyReport'" />
    <router-view v-bind:style="[ currentRouteName === 'DailyReport' ? {} : {'margin-left': '15vw'} ]" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";

import TopPanel from "./components/TopPanel";
import LeftPanel from "./components/LeftPanel";
import Loader from "./components/Loader";
import "./styles/main.css";

export default {
  name: "App",
  components: {
    loader: Loader,
    "top-panel": TopPanel,
    "left-panel": LeftPanel,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  setup() {
    useI18n({ useScope: "global" });
    const store = useStore();
    // const router = useRouter();
    const pageLoading = computed(
      () => store.getters["loader/getLoadingStatus"]
    );
    const loginLoading = computed(() => store.getters["auth/getLoginLoading"]);
    // watch(
    //     () => store.getters['auth/getIsLoggedIn'],
    //     isLoggedIn => {
    //       if(!isLoggedIn) {
    //         router.push({ name: 'Login' })
    //       }
    //     }
    // )

    return {
      loginLoading,
      pageLoading,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

.main-panel {
  display: flex;
}


</style>
