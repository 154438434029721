import spawnerService from "@/services/spawner"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    spawner: null
})

// getters
const getters = {
    getSpawner: ({ spawner }) => {
        return spawner
    }
}

// actions
const actions = {
    updateSpawner({ commit }, spawner) {
        spawnerService.setSpawner(spawner).then(() => {
            commit('setSpawner', spawner)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SPAWNER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SPAWNER'),
                type: 'error'
            })
        })
    },
    exportSpawner({ commit }, { spawner, environment }) {
        spawnerService.exportSpawner(spawner, environment).then(() => {
            commit('setSpawner', spawner)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_SPAWNER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_SPAWNER'),
                type: 'error'
            })
        })
    },
    getSpawner({ commit }) {
        spawnerService.getSpawner().then(spawner => {
            commit('setSpawner', spawner)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_SPAWNER'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setSpawner(state, spawner) {
        state.spawner = spawner
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}