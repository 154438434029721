import { createI18n } from 'vue-i18n'

const loadMessages = () => {
    const locales = require.context('@/locales', true, /[\w-]+\.json$/i)
    return locales.keys().reduce((locs, loc) => ({ ...locs, [loc.replace(/\.|\/|json/g, '')]: locales(loc) }), {})
}

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadMessages()
})