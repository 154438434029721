import getAxios from './axios'


export default {
    setServerConfig: serverConfig => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server-config/set', serverConfig).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SERVER_CONFIG'))
            })
        })
    },
    exportServerConfig: (serverConfig, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server-config/export', { serverConfig, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SERVER_CONFIG'))
            })
        })
    },
    getServerConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/server-config/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SERVER_CONFIG'))
            })

        })
    },
    setQuests: quests => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/quests/set', quests).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_QUESTS'))
            })
        })
    },
    exportQuests: (quests, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/quests/export', { quests, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_QUESTS'))
            })
        })
    },
    getQuests: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/quests/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_QUESTS'))
            })

        })
    },
    getLeaderBotConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/leaderbots/get-configuration').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_LB_CONFIG'))
            })

        })
    },
    setLeaderBotConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/set-configuration', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_LB_CONFIG'))
            })
        })
    },
    getMiniTournamentConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/mini-tournament/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_CONFIG'))
            })

        })
    },
    getFactionsConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/factions/get-config').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_FACTION_CONFIG'))
            })

        })
    },
    setFactionsConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/factions/set-config', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_FACTION_CONFIG'))
            })

        })
    },
    setMiniTournamentConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mini-tournament/set', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_CONFIG'))
            })
        })
    },
    exportMiniTournamentConfig: (config, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mini-tournament/export', { data: config, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_CONFIG'))
            })
        })
    },
    getDynamicDifficultyConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/dynamic-difficulty/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_CONFIG'))
            })

        })
    },
    setDynamicDifficultyConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/dynamic-difficulty/set', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_CONFIG'))
            })
        })
    },
    exportDynamicDifficultyConfig: (config, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/dynamic-difficulty/export', { data: config, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_CONFIG'))
            })
        })
    },
    getMatchmakingConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/gamemodifiers/get-config').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_CONFIG'))
            })

        })
    },
    setMatchmakingConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/set-config', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_CONFIG'))
            })
        })
    },
    exportMatchmakingConfig: (config, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/export-config', { data: config, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_CONFIG'))
            })
        })
    },
    getCoopAfkDetectorConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/coop/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_CONFIG'))
            })

        })
    },
    setCoopAfkDetectorConfig: config => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/coop/set', config).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_CONFIG'))
            })
        })
    },
    exportCoopAfkDetectorConfig: (config, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/coop/export', { data: config, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_CONFIG'))
            })
        })
    },
}