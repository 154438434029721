import { createWebHistory, createRouter } from "vue-router";

import store from "../store"

const Login = () => import("@/pages/LoginPage")
const ServerConfig = () => import("@/pages/config")
const Spawner = () => import("@/pages/spawners")
const CoopSpawners = () => import("@/pages/spawners/Coop")
const Units = () => import("@/pages/units")
const UnitAdd = () => import("@/pages/units/Unit")
//const UnitsNext = () => import("@/pages/unitsNext")
//const UnitNextAdd = () => import("@/pages/unitsNext/Unit")
const Heroes = () => import("@/pages/heroes")
const HeroAdd = () => import("@/pages/heroes/Hero")
const Bosses = () => import("@/pages/bosses")
const BossAdd = () => import("@/pages/bosses/Boss")
const User = () => import("@/pages/users")
const News = () => import("@/pages/news")
const NewsAdd = () => import("@/pages/news/News")
const Mails = () => import("@/pages/mails")
const MailAdd = () => import("@/pages/mails/Mail")
const Shop = () => import("@/pages/shop")
const Promotions = () => import("@/pages/promotions")
const SpecialOffers = () => import("@/pages/specialOffers")
const PromotionAdd = () => import("@/pages/promotions/Promotion")
const SpecialOfferAdd = () => import("@/pages/specialOffers/SpecialOffer")
const Logs = () => import("@/pages/logs")
const GameLogs = () => import("@/pages/gameLogs")
const Purchases = () => import("@/pages/purchases")
const Monitor = () => import("@/pages/monitor/Monitor")
const BotDecks = () => import("@/pages/botDecks")
const BotDeckAdd = () => import("@/pages/botDecks/BotDeck")
const Leaderbots = () => import("@/pages/leaderbots")
const LeaderbotAdd = () => import("@/pages/leaderbots/Leaderbot")
const MirrorDecks = () => import("@/pages/mirrorDecks")
const MirrorDeckAdd = () => import("@/pages/mirrorDecks/MirrorDeck")
const GameModeDecks = () => import("@/pages/gameModeDecks")
const GameModeDecksAdd = () => import("@/pages/gameModeDecks/GameModeDeck")
const Wiki = () => import("@/pages/wiki")
const WikiAdd = () => import("@/pages/wiki/Wiki")
const Rewards = () => import("@/pages/rewards")
const SevenDaysLoginReward = () => import("@/pages/rewards/SevenDaysLogin")
const ExperienceRewards = () => import('@/pages/rewards/Experience')
const DailyLoginReward = () => import("@/pages/rewards/DailyLogin")
const FirstPurchaseReward = () => import("@/pages/rewards/FirstPurchase")
const TipsTricks = () => import("@/pages/tips")
const Assets = () => import("@/pages/assets")
const Seasons = () => import("@/pages/seasons")
const SeasonUpdate = () => import("@/pages/seasons/Season")
const SeasonContent = () => import("@/pages/seasons/SeasonContent")
const TrophyLeaderboard = () => import("@/pages/leaderboard/TrophyLeaderboard")
const MockUsers = () => import("@/pages/mockUsers")
const MockUsersAdd = () => import("@/pages/mockUsers/MockUser")
const TrophyRoad = () => import("@/pages/trophy/Trophy")
const EventChallengeList = () => import("@/pages/events/EventChallengeList")
const EventsAdd = () => import("@/pages/events/Event")
const PassiveEventsAdd = () => import("@/pages/events/PassiveEventsAdd")
const EventRouter = () => import("@/pages/events/EventRouter")
const EventRandomRumble = () => import("@/pages/events/EventRandomRumble")
const TreasureHunt = () => import("@/pages/events/TreasureHunt")
const ThematicLiveOps = () => import("@/pages/events/ThematicLiveOps")
const PassiveEventsList = () => import("@/pages/events/PassiveEventList")
const MiniTournamentConfig = () => import("@/pages/events/MiniTournamentConfig")
const PanelAdmin = () => import("@/pages/panelAdmin")
const PromoCodes = () => import("@/pages/promoCodes")
const PromoCodesAdd = () => import("@/pages/promoCodes/PromoCode")
const Localization = () => import("@/pages/localization")
const Server = () => import("@/pages/server")
const ModifiersMain = () => import("@/pages/modifiers")
const Modifier = () => import("@/pages/modifiers/components/Modifier")
const DailyDeals = () => import("@/pages/shop/DailyDeals")
const GameConstants = () => import("@/pages/gameConstants")
const Quests = () => import("@/pages/quests/Quests")
const DailyReport = () => import("@/pages/monitor/DailyReport")
const LogReport = () => import("@/pages/monitor/LogReport")
const Factions = () => import("@/pages/factionSystem/Factions/index")
const FactionsAdd = () => import("@/pages/factionSystem/Factions/Faction")
const Skills = () => import("@/pages/factionSystem/Skills/index")
const SkillsAdd = () => import("@/pages/factionSystem/Skills/Skill")
const FactionConfig = () => import("@/pages/factionSystem/config")
const DynamicDifficultySystem = () => import("@/pages/config/dynamicDifficultySystem")
const Coop = () => import("@/pages/coop")

const routes = [
    {
      path: "/",
      redirect: { name: 'Login' }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/daily-deals",
        name: "DailyDeals",
        component: DailyDeals,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/localization",
        name: "Localization",
        component: Localization,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/server",
        name: "Server",
        component: Server,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/modifiers-main",
        name: "ModifiersMain",
        component: ModifiersMain,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/modifier",
        name: "Modifier",
        component: Modifier,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/daily-report",
        name: "DailyReport",
        component: DailyReport,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/log-report",
        name: "LogReport",
        component: LogReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/config",
        name: "ServerConfig",
        component: ServerConfig,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/dynamic-difficulty-system",
        name: "DynamicDifficultySystem",
        component: DynamicDifficultySystem,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/coop",
        name: "Coop",
        component: Coop,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units",
        name: "Units",
        component: Units,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units/add",
        name: "UnitAdd",
        component: UnitAdd,
        meta: {
            requiresAuth: true
        }
    },
    /*{
        path: "/units-next",
        name: "UnitsNext",
        component: UnitsNext,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units-next/add",
        name: "UnitNextAdd",
        component: UnitNextAdd,
        meta: {
            requiresAuth: true
        }
    },*/
    {
        path: "/heroes",
        name: "Heroes",
        component: Heroes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/heroes/add",
        name: "HeroAdd",
        component: HeroAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bosses",
        name: "Bosses",
        component: Bosses,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bosses/add",
        name: "BossAdd",
        component: BossAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/users",
        name: "User",
        component: User,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/spawners",
        name: "Spawner",
        component: Spawner,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/coop-spawners",
        name: "CoopSpawners",
        component: CoopSpawners,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/news",
        name: "News",
        component: News,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/news/add",
        name: "NewsAdd",
        component: NewsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mails",
        name: "Mails",
        component: Mails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mails/add",
        name: "MailAdd",
        component: MailAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/shop",
        name: "Shop",
        component: Shop,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promo-codes",
        name: "PromoCodes",
        component: PromoCodes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/quests",
        name: "Quests",
        component: Quests,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promo-codes/add",
        name: "PromoCodesAdd",
        component: PromoCodesAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promotions",
        name: "Promotions",
        component: Promotions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promotions/add",
        name: "PromotionAdd",
        component: PromotionAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/special-offers",
        name: "SpecialOffers",
        component: SpecialOffers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/special-offers/add",
        name: "SpecialOfferAdd",
        component: SpecialOfferAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/logs",
        name: "Logs",
        component: Logs,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-logs",
        name: "GameLogs",
        component: GameLogs,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/purchases",
        name: "Purchases",
        component: Purchases,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/monitor",
        name: "Monitor",
        component: Monitor,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/leaderbots",
        name: "Leaderbots",
        component: Leaderbots,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/leaderbots/add",
        name: "LeaderbotAdd",
        component: LeaderbotAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bot-decks",
        name: "BotDecks",
        component: BotDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bot-decks/add",
        name: "BotDeckAdd",
        component: BotDeckAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mirror-decks",
        name: "MirrorDecks",
        component: MirrorDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mirror-decks/add",
        name: "MirrorDeckAdd",
        component: MirrorDeckAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-mode-decks",
        name: "GameModeDecks",
        component: GameModeDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-constants",
        name: "GameConstants",
        component: GameConstants,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/game-mode-decks/add",
        name: "GameModeDeckAdd",
        component: GameModeDecksAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/wiki",
        name: "Wiki",
        component: Wiki,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/wiki/add",
        name: "WikiAdd",
        component: WikiAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards",
        name: "Rewards",
        component: Rewards,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/login",
        name: "SevenDaysLoginReward",
        component: SevenDaysLoginReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/experience",
        name: "ExperienceRewards",
        component: ExperienceRewards,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/daily-login",
        name: "DailyLoginReward",
        component: DailyLoginReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/first-purchase",
        name: "FirstPurchaseReward",
        component: FirstPurchaseReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/tips-tricks",
        name: "TipsTricks",
        component: TipsTricks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/assets",
        name: "Assets",
        component: Assets,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/seasons",
        name: "Seasons",
        component: Seasons,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/seasons/update",
        name: "SeasonUpdate",
        component: SeasonUpdate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/season-content",
        name: "SeasonContent",
        component: SeasonContent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/leaderboard-trophy",
        name: "TrophyLeaderboard",
        component: TrophyLeaderboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mock-users",
        name: "MockUsers",
        component: MockUsers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mock-users/add",
        name: "MockUsersAdd",
        component: MockUsersAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/trophy-road",
        name: "TrophyRoad",
        component: TrophyRoad,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/challenge",
        name: "EventChallengeList",
        component: EventChallengeList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/mini-tournament-config",
        name: "MiniTournamentConfig",
        component: MiniTournamentConfig,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/passive",
        name: "PassiveEventList",
        component: PassiveEventsList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/random-rumble",
        name: "EventRandomRumble",
        component: EventRandomRumble,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/treasure-hunt",
        name: "TreasureHunt",
        component: TreasureHunt,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/thematic-liveops",
        name: "ThematicLiveOpsMain",
        component: ThematicLiveOps,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events-main",
        name: "Events",
        component: EventRouter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/challenge/add",
        name: "EventsAdd",
        component: EventsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/passive/add",
        name: "PassiveEventAdd",
        component: PassiveEventsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/panel-admin",
        name: "PanelAdmin",
        component: PanelAdmin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/factions",
        name: "Factions",
        component: Factions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/factions/add",
        name: "FactionsAdd",
        component: FactionsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/skills",
        name: "Skills",
        component: Skills,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/skills/add",
        name: "SkillsAdd",
        component: SkillsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/faction-config",
        name: "FactionConfig",
        component: FactionConfig,
        meta: {
            requiresAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

let loadTimer = null;

/*router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if(to.matched.some(record => record.meta.requiresAuth)){
        store.dispatch("loader/loadingStatus", true);
        console.log(1)
    }
    next()
  })
  
  router.afterEach(() => {
    // Complete the animation of the route progress bar.
    store.dispatch("loader/loadingStatus", false);
    console.log(2)

  })*/


router.beforeEach((to, from, next) => {

    if(store.getters['auth/getLoginLoading']) {
        loadTimer = setInterval(() => {
            console.log('Timer tick');
            const loginLoading = store.getters['auth/getLoginLoading'];
            console.log('Login loading: ', loginLoading);
            if(!loginLoading) {
                clearInterval(loadTimer);
                loadTimer = null;
                if(to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/getIsLoggedIn']) {
                    next({ name: 'Login' })
                } else {
                    next()
                }
            }
        }, 500)
    } else {
        if(to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/getIsLoggedIn']) {
            next({ name: 'Login' })
        } else {
            next()
        }
    }

})

export default router;