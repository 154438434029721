import axios from 'axios';

import getFirebaseIdToken from './helper/httpHelper'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || ''
axios.defaults.withCredentials = true
console.log(axios.defaults.baseURL)

const getAxios = async () => {
    console.log(axios.defaults.baseURL)
    const token = await getFirebaseIdToken()
    axios.defaults.headers.common = {
        'authorization': token || ''
    }
    return axios
}
export default getAxios;