import getAxios from './axios'

export default {
    deleteMockUser: user_id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mock-users/delete', { user_id }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_REMOVE_MOCK_USER'))
            })
        })
    },
    addMockUser: userBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mock-users/add', userBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_MOCK_USER'))
            })
        })
    },
    editMockUser: (userId, userBody) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mock-users/edit', { user_body: userBody, user_id: userId }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EDIT_MOCK_USER'))
            })
        })
    },
    getMockUsers: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/mock-users/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_MOCK_USERS'))
            })

        })
    }

}