import getAxios from './axios'

export default {
    getSkills: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/skills/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_NEWS'))
            })
        })
    },
    addSkill: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/skills/add').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_NEWS'))
            })
        })
    },
    updateSkill: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/skills/update', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_FACTION'))
            })
        })
    },
    exportSkill: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/skills/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_SKILL'))
            })
        })
    },
}