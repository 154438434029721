import getAxios from './axios'

export default {
    getWiki: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/wiki/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_WIKI'))
            })
        })
    },
    setWiki: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/wiki/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_WIKI'))
            })
        })
    },
    exportWiki: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/wiki/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_WIKI'))
            })
        })
    },
    deleteWiki: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/wiki/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_WIKI'))
            })
        })
    }

}