import firebase from 'firebase/app'

export default async () => {
    try {
        return await firebase
            .auth()
            .currentUser.getIdToken()
            .catch(() => {
                return null
            });
    } catch {
        return null
    }

}