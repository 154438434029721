import getAxios from './axios'

export default {
    getMails: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/mail/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_MAILS'))
            })
        })
    },
    setMail: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mail/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_MAIL'))
            })
        })
    },
    exportMail: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mail/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_MAIL'))
            })
        })
    },
    deleteMail: id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mail/delete', { doc_id: id}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_MAIL'))
            })
        })
    },
    addMail: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mail/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_MAIL'))
            })
        })
    },

}