import leaderbotService from "@/services/leaderbot"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    leaderbots: [],
})

// getters
const getters = {
    getLeaderbots: ({ leaderbots }) => {
        return leaderbots
    },
}

// actions
const actions = {
    getLeaderbots({ commit }) {
        leaderbotService.getLeaderbots().then(leaderbots => {
            commit('setLeaderbots', leaderbots)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_LEADERBOTS'),
                type: 'error'
            })
        })
    },
    async updateLeaderbot(state, { id, data }) {
        return leaderbotService.setLeaderbot(id, data).then(() => {
            store.dispatch('leaderbots/getLeaderbots')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_LEADERBOT'),
                type: 'success'
            })
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_LEADERBOT'),
                type: 'error'
            })
            return false;
        })
    },
    exportLeaderbot(state, { id, data, environment }) {
        leaderbotService.exportLeaderbot(id, data, environment).then(() => {
            store.dispatch('leaderbots/getLeaderbots')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_LEADERBOT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_LEADERBOT'),
                type: 'error'
            })
        })
    },
    addLeaderbot(state, { data }) {
        leaderbotService.addLeaderbot(data).then(() => {
            store.dispatch('leaderbots/getLeaderbots')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_LEADERBOT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_LEADERBOT'),
                type: 'error'
            })
        })
    },
    deleteLeaderbot(state, { id, index }) {
        leaderbotService.deleteLeaderbot({ id, index }).then(() => {
            store.dispatch('leaderbots/getLeaderbots')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_LEADERBOT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_LEADERBOT'),
                type: 'error'
            })
        })
    },
    exportAllLeaderbots(state, data) {
        leaderbotService.exportAllLeaderbots(data).then(() => {
            store.dispatch('leaderbots/getLeaderbots')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_LEADERBOTS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_LEADERBOTS'),
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setLeaderbots(state, leaderbots) {
        state.leaderbots = leaderbots;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}