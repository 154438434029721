import getAxios from './axios'


export default {
    setResourceFields: updateBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/resources/set', updateBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_RESOURCES'))
            })
        })
    },
    exportResourceFields: (updateBody, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/resources/export', { updateBody, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_RESOURCES'))
            })
        })
    },
    getResources: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/resources/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_RESOURCES'))
            })

        })
    }

}