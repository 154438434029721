export const leftPanelConstants = {
    assets: ['assets', 'Assets','ASSETS'],
    units: ['units', 'Units', 'UNITS_RANDOM', "Characters"],
    heroes: ['heroes', 'Heroes', 'HEROES', "Characters"],
    bosses: ['bosses', 'Bosses', 'BOSSES', "Characters"],
    spawners: ['spawners', 'Spawner', 'SPAWNERS', "Spawners"],
    coopSpawners: ['coop-spawners', 'CoopSpawners', 'COOP_SPAWNERS', "Spawners"],
    config: ['config', 'ServerConfig', 'CONFIG'],
    users: ['users', 'User', 'USERS'],
    news: ['news', 'News', 'NEWS', "Community"],
    mails: ['mails', 'Mails', 'MAILS', "Community"],
    shop: ['shop', 'Shop', 'SHOP', "Monetization"],
    promo_codes: ['promo-codes', 'PromoCodes', 'Promo Codes', "Community"], //promo-codes
    promotions: ['promotions', 'Promotions', 'PROMOTIONS', "Monetization"],
    special_offers: ['special-offers', 'SpecialOffers', 'SPECIAL_OFFERS', "Monetization"], //special-offers
    logs: ['logs', 'Logs', 'LOGS', "Monitor & Logs"],
    game_logs: ['game-logs', 'GameLogs', 'Game Logs', "Monitor & Logs"], //game-logs
    log_report: ['log-report', 'LogReport', 'Log Report', "Monitor & Logs"],
    purchases: ['purchases', 'Purchases', 'Purchases', "Monetization"],
    monitoring: ['monitor', 'Monitor', 'MONITOR', "Monitor & Logs"],
    bot_decks: ['bot-decks', 'BotDecks', 'BOT_DECKS', "Bots"], //bot-decks
    leaderbots: ['leaderbots', 'Leaderbots', 'LEADERBOTS', "Bots"], //bot-decks
    mirror_decks: ['mirror-decks', 'MirrorDecks', 'MIRROR_DECKS', "Bots"], //mirror-decks
    game_mode_decks: ['game-mode-decks', 'GameModeDecks', 'GAME_MODE_DECKS', "Bots"], //game-mode-decks
    wiki: ['wiki', 'Wiki', 'WIKI'],
    rewards: ['rewards', 'Rewards', 'REWARDS'],
    tips_tricks: ['tips-tricks', 'TipsTricks', 'TIPS_TRICKS'], //tips-tricks
    seasons: ['seasons', 'Seasons', 'SEASONS', "Season"],
    season_content: ['season-content', 'SeasonContent', 'SEASON_CONTENT', "Season"], //season-content
    leaderboard_trophy: ['leaderboard-trophy', 'TrophyLeaderboard', 'TROPHY_LEADERBOARD', "Season"], //leaderboard-trophy
    trophy_road: ['trophy-road', 'TrophyRoad', 'TROPHY_ROAD'], //trophy-road
    events: ['events-main', 'Events', 'EVENTS'],
    panel_admin: ['panel-admin', 'PanelAdmin', 'PANEL_ADMIN'], //panel-admin
    localization: ['localization', 'Localization', 'LOCALIZATION'],
    server: ['server', 'Server', 'SERVER'],
    modifiers: ['modifiers', 'ModifiersMain', 'MODIFIERS'],
    daily_deals: ['daily-deals', 'DailyDeals', 'DAILY_DEALS'],
    game_constants: ['game-constants', 'GameConstants', 'GAME_CONSTANTS'],
    quests: ['quests', 'Quests', 'QUESTS'],
    factions: ['factions', 'Factions', 'Factions', 'Faction System'],
    skills: ['skills', 'Skills', 'Skills', 'Faction System'],
    faction_config: ['faction-config', 'FactionConfig', 'Faction Config', 'Faction System'],
    dynamic_difficulty: ['dynamic-difficulty-system', 'DynamicDifficultySystem', 'Dynamic Difficulty System'],
    coop: ['coop', 'Coop', 'Coop']
};

