import langService from "@/services/language"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    languages: []
})

// getters
const getters = {
    getLanguages: ({ languages }) => {
        console.log("GETTER CALL")
        return languages
    }
}

// actions
const actions = {
    getLanguages({ commit }) {
        langService.getLanguages().then(languages => {
            commit('setLanguages', languages)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_LANGUAGES'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setLanguages(state, languages) {
        state.languages = languages
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}