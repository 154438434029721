import unitService from "@/services/unit"
import store from "@/store"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    units: []
})

// getters
const getters = {
    getUnits: ({ units }) => {
        return units
    }
}

// actions
const actions = {
    getUnits({ commit }) {
        return unitService.getUnits().then(units => {
            commit('setUnits', units)
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_UNITS'),
                type: 'error'
            })
            return false
        })
    },
    updateUnit(state, { id, data }) {
        unitService.setUnit(id,data).then(() => {
            store.dispatch('units/getUnits')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_UNIT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_UNIT'),
                type: 'error'
            })
        })
    },
    updateUnitFields(state, { ids, data }) {
        return unitService.setUnitFields(ids,data).then(() => {
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_UNIT_FIELDS'),
                type: 'success'
            })
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_UNIT_FIELDS'),
                type: 'error'
            })
            return false;
        })
    },
    updateUnitNoResync(state, { id, data }) {
        return unitService.setUnit(id,data).then(() => {
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_UNIT'),
                type: 'error'
            })
            return false
        })
    },
    exportUnit(state, { id, data, environment }) {
        unitService.exportUnit(id,data, environment).then(() => {
            store.dispatch('units/getUnits')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_UNIT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_UNIT'),
                type: 'error'
            })
        })
    },
    addUnit(state, { data }) {
        unitService.addUnit(data).then(() => {
            store.dispatch('units/getUnits')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_UNIT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_UNIT'),
                type: 'error'
            })
        })
    },
    deleteUnit(state, { id, index }) {
        unitService.deleteUnit({ id, index }).then(() => {
            store.dispatch('units/getUnits')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_UNIT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_UNIT'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setUnits(state, units) {
        state.units = units
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}