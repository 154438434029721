import wikiService from "@/services/wiki"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    wiki: []
})

// getters
const getters = {
    getWiki: ({ wiki }) => {
        return wiki
    }
}

// actions
const actions = {
    getWiki({ commit }) {
        wikiService.getWiki().then(wiki => {
            commit('setWiki', wiki)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_WIKI'),
                type: 'error'
            })
        })
    },
    updateWiki(state, { id, data }) {
        wikiService.setWiki(id,data).then(() => {
            store.dispatch('wiki/getWiki')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_WIKI'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_WIKI'),
                type: 'error'
            })
        })
    },
    exportWiki(state, { id, data, environment }) {
        wikiService.exportWiki(id,data, environment).then(() => {
            store.dispatch('wiki/getWiki')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_WIKI'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_WIKI'),
                type: 'error'
            })
        })
    },
    deleteWiki(state, { id, index }) {
        wikiService.deleteWiki({ id, index }).then(() => {
            store.dispatch('wiki/getWiki')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_WIKI'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_WIKI'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setWiki(state, wiki) {
        state.wiki = wiki
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}