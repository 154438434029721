import getAxios from './axios'


export default {
    updateSeason: (id, updateBody) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/season/update', { id, updateBody }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SEASON'))
            })
        })
    },
    addSeason: seasonBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/season/add', seasonBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_SEASON'))
            })
        })
    },
    getSeasons: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/season/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SEASONS'))
            })

        })
    },
    getSeasonPassContent: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/season/get-content').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SEASON_PASS_CONTENT'))
            })

        })
    },
    updateSeasonPassContent: seasonBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/season/update-content', seasonBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_UPDATE_SEASON_PASS_CONTENT'))
            })
        })
    },
    exportSeasonContent: (seasonContent, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/season/export-content', { seasonContent, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_UPDATE_SEASON_PASS_CONTENT'))
            })
        })
    },

}