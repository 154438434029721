import firebase from "firebase/app";
import 'firebase/auth'
import getAxios from "./axios";

export default {
    login: ({ username, password }) => {
        return new Promise((resolve, reject) => {
            if(!username || !password) {
                reject('ERROR_LOGIN_EMPTY_FIELD')
            }
            firebase
                .auth()
                .signInWithEmailAndPassword(username, password)
                .then(user => {
                    resolve(user)
                })
                .catch(() => {
                    reject('ERROR_FIREBASE_LOGIN_FAILED')
                });
        })
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve()
            }).catch(() => {
                reject('ERROR_FAILED_TO_SIGN_OUT')
            });
        })
    },
    getAppConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/app-config').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_APP_CONFIG'))
            })
        })
    },
    sendPasswordResetEmail: email => {
        return firebase.auth().sendPasswordResetEmail(email);
    }
}