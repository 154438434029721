import gamemodifierService from "@/services/gameModifier"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    gameModifiers: []
})

// getters
const getters = {
    getGameModifiers: ({ gameModifiers }) => {
        return gameModifiers
    }
}

// actions
const actions = {
    getGameModifiers({ commit }) {
        gamemodifierService.getGameModifiers().then(gameModifiers => {
            commit('setGameModifiers', gameModifiers)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_MODIFIERS'),
                type: 'error'
            })
        })
    },
    updateGameModifier(state, { id, data }) {
        return gamemodifierService.setGameModifier(id,data).then(() => {
            store.dispatch('gameModifiers/getGameModifiers')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_MODIFIER'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_MODIFIER'),
                type: 'error'
            })
            return false
        })
    },
    exportGameModifier(state, { id, data, environment }) {
        gamemodifierService.exportGameModifier(id, data, environment).then(() => {
            store.dispatch('gameModifiers/getGameModifiers')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_MODIFIER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_MODIFIER'),
                type: 'error'
            })
        })
    },
    addGameModifier(state, { data }) {
        return gamemodifierService.addGameModifiers(data).then(() => {
            store.dispatch('gameModifiers/getGameModifiers')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_MODIFIER'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_MODIFIER'),
                type: 'error'
            })
            return true
        })
    },
    deleteGameModifier(state, { id, index }) {
        gamemodifierService.deleteGameModifier({ id, index }).then(() => {
            store.dispatch('gameModifiers/getGameModifiers')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_MODIFIER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_MODIFIER'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setGameModifiers(state, gameModifiers) {
        state.gameModifiers = gameModifiers
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}