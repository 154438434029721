import eventService from "@/services/event"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    events: []
})

// getters
const getters = {
    getEvents: ({ events }) => {
        return events
    }
}

// actions
const actions = {
    updateEvent(state, { id, updateBody }) {
        return eventService.updateEvent(id, updateBody).then(() => {
            store.dispatch('events/getEvents')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_EVENT'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_EVENT'),
                type: 'error'
            })
            return false
        })
    },
    addEvent(state, eventBody) {
        return eventService.addEvent(eventBody).then(() => {
            store.dispatch('events/getEvents')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_EVENT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_EVENT'),
                type: 'error'
            })
        })
    },
    getEvents({ commit }) {
        return eventService.getEvents().then(events => {
            commit('setEvents', events)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_EVENTS'),
                type: 'error'
            })
        })
    },
    deleteEvent(state, id) {
        return eventService.deleteEvent(id).then(() => {
            store.dispatch('events/getEvents')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_EVENT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_EVENT'),
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setEvents(state, events) {
        state.events = events
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}