import getAxios from './axios'

export default {
    getFactions: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/factions/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_NEWS'))
            })
        })
    },
    addFaction: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/factions/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_FACTION'))
            })
        })
    },
    updateFaction: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/factions/update', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_FACTION'))
            })
        })
    },
    exportFaction: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/factions/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_FACTION'))
            })
        })
    },
}