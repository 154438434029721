import getAxios from './axios'

export default {
    getHeroes: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/hero/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_HEROES'))
            })
        })
    },
    setHero: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/hero/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_HERO'))
            })
        })
    },
    exportHero: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/hero/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_HERO'))
            })
        })
    },
    deleteHero: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/hero/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_HERO'))
            })
        })
    },
    addHero: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/hero/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_HERO'))
            })
        })
    },

}