import seasonService from "@/services/season"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    seasons: [],
    seasonContent: {
        milestones: [],
        grind: {
            step: 500,
            amount: 500
        }
    }
})

// getters
const getters = {
    getSeasons: ({ seasons }) => {
        return seasons
    },
    getSeasonContent: ({ seasonContent }) => {
        return seasonContent
    }
}

// actions
const actions = {
    updateSeason(state, { id, updateBody }) {
        return seasonService.updateSeason(id, updateBody).then(() => {
            store.dispatch('seasons/getSeasons')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SEASON'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SEASON'),
                type: 'error'
            })
        })
    },
    addSeason(state, seasonBody) {
        return seasonService.addSeason(seasonBody).then(() => {
            store.dispatch('seasons/getSeasons')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_SEASON'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_SEASON'),
                type: 'error'
            })
        })
    },
    editSeason(state, { id, updateBody }) {
        return seasonService.updateSeason(id, updateBody).then(() => {
            store.dispatch('seasons/getSeasons')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SEASON'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SEASON'),
                type: 'error'
            })
        })
    },
    getSeasons({ commit }) {
        return seasonService.getSeasons().then(seasons => {
            commit('setSeasons', seasons)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_SEASONS'),
                type: 'error'
            })
        })
    },
    getSeasonPassContent({ commit }) {
        return seasonService.getSeasonPassContent().then(seasonContent => {
            commit('setSeasonContent', seasonContent)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_SEASON_CONTENT'),
                type: 'error'
            })
        })
    },
    editSeasonContent(state, seasonContent) {
        return seasonService.updateSeasonPassContent(seasonContent).then(() => {
            store.dispatch('seasons/getSeasonPassContent')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SEASON_CONTENT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SEASON_CONTENT'),
                type: 'error'
            })
        })
    },
    exportSeasonContent({ commit }, { seasonContent, environment }) {
        seasonService.exportSeasonContent(seasonContent, environment).then(() => {
            commit('setSeasonContent', seasonContent)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_SEASON_CONTENT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_SEASON_CONTENT'),
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setSeasons(state, seasons) {
        state.seasons = seasons
    },
    setSeasonContent(state, seasonContent) {
        state.seasonContent = seasonContent
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}