import serverConfigService from "@/services/serverConfig"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    serverConfig: null,
    quests: null,
    leaderBotConfig: null,
    miniTournamentConfig: null,
    factionsConfig: null,
    dynamicDifficultyConfig: null,
    matchmakingConfig: null,
    coopAfkDetectorConfig: null
})

// getters
const getters = {
    getServerConfig: ({ serverConfig }) => {
        return serverConfig
    },
    getQuests: ({ quests }) => {
        return quests
    },
    getLeaderBotConfig: ({ leaderBotConfig }) => {
        return leaderBotConfig
    },
    getMiniTournamentConfig: ({ miniTournamentConfig }) => {
        return miniTournamentConfig
    },
    getFactionsConfig: ({ factionsConfig }) => {
        return factionsConfig;
    },
    getDynamicDifficultyConfig: ({ dynamicDifficultyConfig }) => {
        return dynamicDifficultyConfig
    },
    getMatchmakingConfig: ({ matchmakingConfig }) => {
        return matchmakingConfig
    },
    getCoopAfkDetectorConfig: ({ coopAfkDetectorConfig }) => {
        return coopAfkDetectorConfig
    }
}

// actions
const actions = {
    updateServerConfig({ commit }, serverConfig) {
        return serverConfigService.setServerConfig(serverConfig).then(() => {
            commit('setServerConfig', serverConfig)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    exportServerConfig({ commit }, { serverConfig, environment }) {
        serverConfigService.exportServerConfig(serverConfig, environment).then(() => {
            commit('setServerConfig', serverConfig)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_CONFIG'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_CONFIG'),
                type: 'error'
            })
        })
    },
    getServerConfig({ commit }) {
        serverConfigService.getServerConfig().then(serverConfig => {
            commit('setServerConfig', serverConfig)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
        })
    },
    getQuests({ commit }) {
        serverConfigService.getQuests().then(quests => {
            commit('setQuests', quests)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_QUESTS'),
                type: 'error'
            })
        })
    },
    updateQuests({ commit }, quests) {
        return serverConfigService.setQuests(quests).then(() => {
            commit('setQuests', quests)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_QUESTS'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_QUESTS'),
                type: 'error'
            })
            return false
        })
    },
    getLeaderBotConfig({ commit }) {
        serverConfigService.getLeaderBotConfig().then(config => {
            commit('setLeaderBotConfig', config)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_LB_CONFIG'),
                type: 'error'
            })
        })
    },
    updateLeaderBotConfig({ commit }, config) {
        return serverConfigService.setLeaderBotConfig(config).then(() => {
            commit('setLeaderBotConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_LB_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_LB_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    getMiniTournamentConfig({ commit }) {
        serverConfigService.getMiniTournamentConfig().then(config => {
            commit('setMiniTournamentConfig', config)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
        })
    },
    getFactionsConfig({ commit }) {
        serverConfigService.getFactionsConfig().then(config => {
            commit('setFactionsConfig', config)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
        })
    },
    setFactionsConfig({ commit }, config) {
        serverConfigService.setFactionsConfig(config).then(() => {
            commit('setFactionsConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_FACTIONS_CONFIG'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
        })
    },
    updateMiniTournamentConfig({ commit }, config) {
        return serverConfigService.setMiniTournamentConfig(config).then(() => {
            commit('setMiniTournamentConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    getDynamicDifficultyConfig({ commit }) {
        return serverConfigService.getDynamicDifficultyConfig().then(config => {
            commit('setDynamicDifficultyConfig', config)
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    updateDynamicDifficultyConfig({ commit }, config) {
        return serverConfigService.setDynamicDifficultyConfig(config).then(() => {
            commit('setDynamicDifficultyConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    getMatchmakingConfig({ commit }) {
        return serverConfigService.getMatchmakingConfig().then(config => {
            commit('setMatchmakingConfig', config)
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    updateMatchmakingConfig({ commit }, config) {
        return serverConfigService.setMatchmakingConfig(config).then(() => {
            commit('setMatchmakingConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    getCoopAfkDetectorConfig({ commit }) {
        return serverConfigService.getCoopAfkDetectorConfig().then(config => {
            commit('setCoopAfkDetectorConfig', config)
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
    updateCoopAfkDetectorConfig({ commit }, config) {
        return serverConfigService.setCoopAfkDetectorConfig(config).then(() => {
            commit('setCoopAfkDetectorConfig', config)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
            return false
        })
    },
}

// mutations
const mutations = {
    setServerConfig(state, serverConfig) {
        state.serverConfig = serverConfig
    },
    setQuests(state, quests) {
        state.quests = quests
    },
    setLeaderBotConfig(state, leaderBotConfig) {
        state.leaderBotConfig = leaderBotConfig
    },
    setMiniTournamentConfig(state, miniTournamentConfig) {
        state.miniTournamentConfig = miniTournamentConfig
    },
    setFactionsConfig(state, factionsConfig) {
        state.factionsConfig = factionsConfig
    },
    setDynamicDifficultyConfig(state, ddConfig) {
        state.dynamicDifficultyConfig = ddConfig
    },
    setMatchmakingConfig(state, matchmakingConfig) {
        state.matchmakingConfig = matchmakingConfig
    },
    setCoopAfkDetectorConfig(state, coopAfkDetectorConfig) {
        state.coopAfkDetectorConfig = coopAfkDetectorConfig
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}