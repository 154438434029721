import getAxios from './axios'

export default {
    getBotDecks: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/bot-deck/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_BOT_DECKS'))
            })
        })
    },
    setBotDeck: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/bot-deck/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_BOT_DECK'))
            })
        })
    },
    exportBotDeck: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/bot-deck/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_BOT_DECK'))
            })
        })
    },
    exportAllBotDecks: (environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/bot-deck/export-all', { environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_BOT_DECKS'))
            })
        })
    },
    deleteBotDeck: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/bot-deck/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_BOT_DECK'))
            })
        })
    },
    addBotDeck: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/bot-deck/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_BOT_DECK'))
            })
        })
    },

}