import getAxios from './axios'

export default {
    getPromoCodes: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/promo-code/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_PROMO_CODES'))
            })
        })
    },
    setPromoCode: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promo-code/edit', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_PROMO_CODE'))
            })
        })
    },
    deletePromoCode: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promo-code/delete', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_PROMO_CODE'))
            })
        })
    },
    addPromoCode: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promo-code/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_PROMO_CODE'))
            })
        })
    },
}