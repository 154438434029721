import getAxios from './axios'
import authService from "./auth"

export default {
    getPanelUsers: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/panel-admin/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_PANEL_USERS'))
            })
        })
    },
    updatePanelUserPermissions: (uid, permissions) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/panel-admin/update', { uid, permissions }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_UPDATE_PANEL_USER'))
            })
        })
    },
    createPanelUser: userData => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/panel-admin/create', userData).then(() =>  {
                    authService.sendPasswordResetEmail(userData.email).then(() => resolve(userData)).catch(() => reject('ERROR_CANNOT_CREATE_PANEL_USER'))

                }).catch(() => reject('ERROR_CANNOT_CREATE_PANEL_USER'))
            })
        })
    },
    deletePanelUser: uid => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/panel-admin/delete', { uid }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_PANEL_USER'))
            })
        })
    }

}