import getAxios from './axios'

export default {
    getBosses: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/boss/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_BOSSES'))
            })
        })
    },
    setBoss: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/boss/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_BOSS'))
            })
        })
    },
    exportBoss: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/boss/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_BOSS'))
            })
        })
    },
    deleteBoss: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/boss/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_BOSS'))
            })
        })
    },
    addBoss: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/boss/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_BOSS'))
            })
        })
    },

}