import factionsService from "@/services/factionSystem"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store"

// initial state
const state = () => ({
    factions: []
})

// getters
const getters = {
    getFactions: ({ factions }) => {
        return factions
    }
}

// actions
const actions = {
    getFactions({ commit }) {
        factionsService.getFactions().then(factions => {
            commit('setFactions', factions)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_NEWS'),
                type: 'error'
            })
        })
    },
    async addFaction(state, { data }) {
        return await factionsService.addFaction(data).then(res => {
            store.dispatch('factionSystem/getFactions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_FACTION'),
                type: 'success'
            })
            return res
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_FACTION'),
                type: 'error'
            })
            return null
        })
    },
    updateFaction(state, { id, data }) {
        factionsService.updateFaction(id,data).then(() => {
            store.dispatch('factionSystem/getFactions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_NEWS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_NEWS'),
                type: 'error'
            })
        })
    },
    exportFaction(state, { id, data, environment }) {
        factionsService.exportFaction(id,data, environment).then(() => {
            store.dispatch('factionSystem/getFactions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_FACTION'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_FACTION'),
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setFactions(state, factions) {
        state.factions = factions
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}