import getAxios from './axios'

export default {
    getNews: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/news/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_NEWS'))
            })
        })
    },
    setNews: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/news/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_NEWS'))
            })
        })
    },
    exportNews: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/news/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_NEWS'))
            })
        })
    },
    deleteNews: id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/news/delete', { doc_id: id}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_NEWS'))
            })
        })
    },
    addNews: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/news/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_NEWS'))
            })
        })
    },

}