import mirrorDeckService from "@/services/mirrorDeck"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    mirrorDecks: []
})

// getters
const getters = {
    getMirrorDecks: ({ mirrorDecks }) => {
        return mirrorDecks
    }
}

// actions
const actions = {
    getMirrorDecks({ commit }) {
        mirrorDeckService.getMirrorDecks().then(mirrorDecks => {
            commit('setMirrorDecks', mirrorDecks)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_MIRROR_DECKS'),
                type: 'error'
            })
        })
    },
    updateMirrorDeck(state, { id, data }) {
        mirrorDeckService.setMirrorDeck(id,data).then(() => {
            store.dispatch('mirrorDecks/getMirrorDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_MIRROR_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_MIRROR_DECK'),
                type: 'error'
            })
        })
    },
    exportMirrorDeck(state, { id, data, environment }) {
        mirrorDeckService.exportMirrorDeck(id,data, environment).then(() => {
            store.dispatch('mirrorDecks/getMirrorDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_MIRROR_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_MIRROR_DECK'),
                type: 'error'
            })
        })
    },
    addMirrorDeck(state, { data }) {
        mirrorDeckService.addMirrorDeck(data).then(() => {
            store.dispatch('mirrorDecks/getMirrorDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_MIRROR_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_MIRROR_DECK'),
                type: 'error'
            })
        })
    },
    deleteMirrorDeck(state, { id, index }) {
        mirrorDeckService.deleteMirrorDeck({ id, index }).then(() => {
            store.dispatch('mirrorDecks/getMirrorDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_MIRROR_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_MIRROR_DECK'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setMirrorDecks(state, mirrorDecks) {
        state.mirrorDecks = mirrorDecks
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}