import getAxios from './axios'


export default {
    setSpawner: spawner => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/spawner/edit', spawner).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SPAWNER'))
            })
        })
    },
    exportSpawner: (spawner, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/spawner/export', { spawner, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SPAWNER'))
            })
        })
    },
    getSpawner: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/spawner/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SPAWNER'))
            })

        })
    }

}