import getAxios from "./axios";

export default {
    getChests: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/chest/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_CHESTS'))
            })
        })
    }
}