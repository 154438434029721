import skillsService from "@/services/skills"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store"

// initial state
const state = () => ({
    skills: []
})

// getters
const getters = {
    getSkills: ({ skills }) => {
        return skills
    }
}

// actions
const actions = {
    getSkills({ commit }) {
        skillsService.getSkills().then(skills => {
            commit('setSkills', skills)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_NEWS'),
                type: 'error'
            })
        })
    },
    async addSkill(state, { data }) {
        return await skillsService.addSkill(data).then(res => {
            store.dispatch('skills/getSkills')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_SKILL'),
                type: 'success'
            })
            return res
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_SKILL'),
                type: 'error'
            })
            return null
        })
    },
    updateSkill(state, { id, data }) {
        skillsService.updateSkill(id,data).then(() => {
            store.dispatch('skills/getSkills')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SKILLS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SKILLS'),
                type: 'error'
            })
        })
    },
    exportSkill(state, { id, data, environment }) {
        skillsService.exportSkill(id,data, environment).then(() => {
            store.dispatch('skills/getSkills')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_UNIT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_UNIT'),
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setSkills(state, skills) {
        state.skills = skills
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}