import heroService from "@/services/hero"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    heroes: []
})

// getters
const getters = {
    getHeroes: ({ heroes }) => {
        return heroes
    }
}

// actions
const actions = {
    getHeroes({ commit }) {
        heroService.getHeroes().then(heroes => {
            commit('setHeroes', heroes)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_HEROES'),
                type: 'error'
            })
        })
    },
    updateHero(state, { id, data }) {
        heroService.setHero(id,data).then(() => {
            store.dispatch('heroes/getHeroes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_HERO'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_HERO'),
                type: 'error'
            })
        })
    },
    updateHeroNoResync(state, { id, data }) {
        return heroService.setHero(id,data).then(() => {
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_HERO'),
                type: 'error'
            })
            return false
        })
    },
    exportHero(state, { id, data, environment }) {
        heroService.exportHero(id,data, environment).then(() => {
            store.dispatch('heroes/getHeroes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_HERO'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_HERO'),
                type: 'error'
            })
        })
    },
    addHero(state, { data }) {
        heroService.addHero(data).then(() => {
            store.dispatch('heroes/getHeroes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_HERO'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_HERO'),
                type: 'error'
            })
        })
    },
    deleteHero(state, { id, index }) {
        heroService.deleteHero({ id, index }).then(() => {
            store.dispatch('heroes/getHeroes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_HERO'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_HERO'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setHeroes(state, heroes) {
        state.heroes = heroes
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}