import chestService from "@/services/chest";


const state = () => ({
    chests: []
})

const getters = {
    getChests: ({ chests }) => {
        return chests
    }
}

const actions = {
    getChests({ commit }) {
        chestService.getChests().then(chests => {
            commit('setChests', chests)
        }).catch(() => {
            /*notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_HEROES'),
                type: 'error'
            })*/
        })
    },
}

const mutations = {
    setChests(state, chests) {
        state.chests = chests;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}