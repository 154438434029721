import roadService from "../../services/road"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    trophyRoad: {},
    experienceRoad: undefined
})

// getters
const getters = {
    getTrophyRoad: ({ trophyRoad }) => {
        return trophyRoad
    },
    getExperienceRoad: ({ experienceRoad }) => {
        return experienceRoad
    }
}

// actions
const actions = {
    updateTrophyRoad({ commit }, trophyRoad) {
        roadService.setTrophyRoad(trophyRoad).then(() => {
            commit('setTrophyRoad', trophyRoad)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_TROPHY_ROAD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_TROPHY_ROAD'),
                type: 'error'
            })
        })
    },
    updateExperienceRoad(state, experienceRoad) {
        return roadService.setExperienceRoad(experienceRoad).then(() => {
            store.dispatch('road/getExperienceRoad')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_EXPERIENCE_ROAD'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_EXPERIENCE_ROAD'),
                type: 'error'
            })
            return false
        })
    },
    exportTrophyRoad({ commit }, { trophyRoad, environment }) {
        roadService.exportTrophyRoad(trophyRoad, environment).then(() => {
            commit('setTrophyRoad', trophyRoad)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_TROPHY_ROAD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_TROPHY_ROAD'),
                type: 'error'
            })
        })
    },
    exportExperienceRoad(state, data) {
        console.log(data)
        return roadService.exportExperienceRoad(data.experienceRoad, data.environment).then(() => {
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_EXPERIENCE_ROAD'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_EXPERIENCE_ROAD'),
                type: 'error'
            })
            return false
        })
    },
    getTrophyRoad({ commit }) {
        roadService.getTrophyRoad().then(trophyRoad => {
            commit('setTrophyRoad', trophyRoad)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_TROPHY_ROAD'),
                type: 'error'
            })
        })
    },
    getExperienceRoad({ commit }) {
        roadService.getExperienceRoad().then(experienceRoad => {
            commit('setExperienceRoad', experienceRoad)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_TROPHY_ROAD'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setTrophyRoad(state, trophyRoad) {
        state.trophyRoad = trophyRoad
    },
    setExperienceRoad(state, experienceRoad) {
        state.experienceRoad = experienceRoad
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}