import getAxios from './axios'

export default {
    getPromotions: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/promotion/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_PROMOTIONS'))
            })
        })
    },
    setPromotion: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promotion/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_PROMOTION'))
            })
        })
    },
    exportPromotion: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promotion/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_PROMOTIN'))
            })
        })
    },
    deletePromotion: id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promotion/delete', { doc_id: id}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_PROMOTION'))
            })
        })
    },
    addPromotion: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/promotion/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_PROMOTION'))
            })
        })
    },

}