import getAxios from './axios'

export default {
    getLeaderbots: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/leaderbots/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_LEADERBOTS'))
            })
        })
    },
    setLeaderbot: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_LEADERBOT'))
            })
        })
    },
    exportLeaderbot: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_LEADERBOT'))
            })
        })
    },
    exportAllLeaderbots: (environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/export-all', { environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_EXPORT_LEADERBOTS'))
            })
        })
    },
    deleteLeaderbot: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/delete', { doc_id: id, index }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_LEADERBOT'))
            })
        })
    },
    addLeaderbot: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderbots/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_LEADERBOT'))
            })
        })
    },

}