import getAxios from './axios'


export default {
    updateEvent: (id, updateBody) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/event/update', { id, updateBody }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_EVENT'))
            })
        })
    },
    addEvent: eventBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/event/add', eventBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_EVENT'))
            })
        })
    },
    getEvents: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/event/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_EVENTS'))
            })

        })
    },
    deleteEvent: id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post('/api/event/delete', { id }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_EVENTS'))
            })

        })
    },
}