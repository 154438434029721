import getAxios from './axios'


export default {
    getLanguages: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/languages/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_LANGUAGES'))
            })

        })
    }
}