import getAxios from './axios'

export default {
    getGameModifiers: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/gamemodifiers/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_MODIFIER'))
            })
        })
    },
    setGameModifier: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_MODIFIER'))
            })
        })
    },
    exportGameModifier: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_MODIFIER'))
            })
        })
    },
    deleteGameModifier: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/delete', { doc_id: id, index }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_MODIFIER'))
            })
        })
    },
    addGameModifiers: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/gamemodifiers/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_MODIFIER'))
            })
        })
    }
}