import getAxios from './axios'


export default {
    setShopContent: shopContent => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/shop/set', shopContent).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SHOP_CONTENT'))
            })
        })
    },
    exportShopContent: (shopContent, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/shop/export', { shopContent, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SHOP_CONTENT'))
            })
        })
    },
    exportShopData: (shopContent, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/shop/export-shop', { shopContent, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SHOP_CONTENT'))
            })
        })
    },
    getShopContent: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/shop/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SHOP_CONTENT'))
            })

        })
    }

}