<template>
  <div class="left-panel" :class="!isLoggedIn ? 'do-not-display' : ''">
    <div v-for="(category, index) in categories" v-bind:key="index" class="categories-container">
      <div class="left-panel-item" @click="() => switchCategory(index)" :class="category.is_open ? 'active' : ''">
        {{ category.name }}
      </div>
      <div v-if="category.is_open" class="subcategory-container">
        <a
          v-for="(item, index) in category.pages"
          v-bind:key="index"
          class="left-panel-item padding-left subitem"
          :class="activeTab === leftPanelConstants[item][0] ? 'active' : ''"
          @click="$router.push({ name: leftPanelConstants[item][1] })"
          >{{ $t(leftPanelConstants[item][2]) }}
        </a>
      </div>
    </div>

    <a
      v-for="(item, index) in items"
      v-bind:key="index"
      class="left-panel-item"
      :class="activeTab === leftPanelConstants[item][0] ? 'active' : ''"
      @click="$router.push({ name: leftPanelConstants[item][1] })"
      >{{ $t(leftPanelConstants[item][2]) }}
    </a>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { checkUserPermissionForPage } from "@/util/permissionHelper";
import { leftPanelConstants } from "@/constants/leftPanelConstants";

export default {
  name: "LeftPanel",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters["auth/getIsLoggedIn"]);
    const route = useRoute();
    const claims = computed(() => store.getters["auth/getPermissionClaims"]);
    const activeTab = computed(() => route.path.split("/")[1]);

    const categories = ref([]);
    const items = ref([]);

    const switchCategory = (index) => {
      for (let i = 0; i < categories.value.length; i++) {
        categories.value[i].is_open = i === index ? !categories.value[i].is_open : false
      }
    };

    watch(
      () => store.getters["auth/getPermissionClaims"],
      (claims) => {
        let categoryNames = Object.keys(leftPanelConstants)
          .filter((x) => leftPanelConstants[x].length === 4)
          .sort()
          .map((y) => leftPanelConstants[y][3]).sort();
        categoryNames = [...new Set(categoryNames)];

        let categoryObjects = [];
        let obj;
        for (let i = 0; i < categoryNames.length; i++) {
          obj = {
            is_open: false,
            name: categoryNames[i],
            pages: Object.keys(leftPanelConstants).filter(
              (x) =>
                leftPanelConstants[x].length === 4 &&
                leftPanelConstants[x][3] === categoryNames[i] &&
                checkUserPermissionForPage(leftPanelConstants[x][0], claims)
            ),
          };

          if (obj.pages.length > 0) categoryObjects.push(obj);
        }

        categories.value = categoryObjects;

        items.value = Object.keys(leftPanelConstants)
          .filter(
            (x) =>
              leftPanelConstants[x].length < 4 &&
              checkUserPermissionForPage(leftPanelConstants[x][0], claims)
          )
          .sort();
      }
    );

    return {
      isLoggedIn,
      activeTab,
      claims,
      checkUserPermissionForPage,
      items,
      categories,
      leftPanelConstants,
      switchCategory,
    };
  },
};
</script>

<style scoped>
.left-panel {
  background-color: #00539CFF !important;
  position: fixed;
  z-index: 1019;
  left: 0;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 46px;
  width: 15vw;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  overflow-y: scroll;
  font-weight: bold;
}

.left-panel-item {
  width: 100%;
  cursor: pointer;
  padding: 7.5px 20px;
  box-sizing: border-box;
  text-align: start;
}

.padding-left {
  padding-left: 40px;
}

.left-panel-item:hover {
  background-color: #0064A8FF;
}

.left-panel.do-not-display {
  display: none;
}

.left-panel-item.active {
  background-color: #004595F6;
}

.subcategory-container{
  display: flex;
  flex-direction: column;
} 

.categories-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.subitem {
  background-color: #99aab5;
}

.subitem:hover {
  background-color: #91a5ad;
}

.subitem.active {
  background-color: #85a0a9;
}

</style>
