import leaderboardService from "@/services/leaderboard"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    trophyLeaderboard: {
        leaderboard: [],
        minTrophy: 0,
        currentLength: 0
    }
})

// getters
const getters = {
    getTrophyLeaderboard: ({ trophyLeaderboard }) => {
        return trophyLeaderboard
    }
}

// actions
const actions = {
    getTrophyLeaderboard({ commit }) {
        leaderboardService.getTrophyLeaderboard().then(trophyLeaderboard => {
            commit('setTrophyLeaderboard', trophyLeaderboard)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_TROPHY_LEADERBOARD'),
                type: 'error'
            })
        })
    },
    updateBannerAsset(state, assetRef) {
        leaderboardService.updateLeaderboardBannerAsset(assetRef).then(() => {
            store.dispatch('leaderboard/getTrophyLeaderboard')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_LEADERBOARD_BANNER_ASSET'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_LEADERBOARD_BANNER_ASSET'),
                type: 'error'
            })
        })
    },
    deleteMockUser(state, id) {
        leaderboardService.deleteMockUser(id).then(() => {
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.REMOVE_MOCK_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.REMOVE_MOCK_USER'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setTrophyLeaderboard(state, trophyLeaderboard) {
        state.trophyLeaderboard = trophyLeaderboard
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}