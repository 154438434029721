import getAxios from './axios'

export default {
    getRewards: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/reward/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_REWARDS'))
            })
        })
    },
    setReward: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/reward/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_REWARD'))
            })
        })
    },
    exportReward: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/reward/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_REWARD'))
            })
        })
    }
}