import getAxios from './axios'

export default {
    deleteMockUser: user_id => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderboard/trophy/delete', { user_id }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_REMOVE_MOCK_USER'))
            })
        })
    },
    addMockUser: userBody => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderboard/trophy/add', userBody).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_MOCK_USER'))
            })
        })
    },
    updateLeaderboardBannerAsset: banner_asset => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/leaderboard/trophy/update-banner', { banner_asset }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_UPDATE_BANNER_ASSET'))
            })
        })
    },
    getTrophyLeaderboard: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/leaderboard/trophy/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_TROPHY_LEADERBOARD'))
            })

        })
    }

}