import auth from './auth'
import units from './units'
import heroes from './heroes'
import bosses from './bosses'
import user from './user'
import serverConfig from './serverConfig'
import spawners from "./spawners"
import news from "./news"
import mails from "./mails"
import shop from "./shop"
import promotions from "./promotions"
import botDecks from "./botDecks"
import mirrorDecks from "./mirrorDecks"
import gameModeDecks from "./gameModeDecks"
import wiki from "./wiki"
import rewards from "./rewards"
import chests from './chests'
import resources from "./resources"
import assets from "./assets"
import languages from "./languages"
import seasons from "./seasons"
import leaderboard from "./leaderboard"
import mockUsers from "./mockUsers"
import events from "./events"
import road from "./road"
import panelAdmin from "./panelAdmin";
import promoCodes from "./promoCodes"
import loader from "./loader"
import localization from "./localization"
import leaderbots from './leaderbots'
import factionSystem from './factionSystem'
import gameModifiers from './gameModifiers'
import skills from './skills'
export default {
    auth,
    serverConfig,
    units,
    heroes,
    bosses,
    user,
    spawners,
    news,
    mails,
    shop,
    promotions,
    botDecks,
    wiki,
    rewards,
    chests,
    resources,
    assets,
    seasons,
    mirrorDecks,
    gameModeDecks,
    leaderboard,
    mockUsers,
    road,
    events,
    panelAdmin,
    languages,
    promoCodes,
    loader,
    localization,
    leaderbots,
    factionSystem,
    skills,
    gameModifiers
}