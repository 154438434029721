import resourceService from "@/services/resources"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    resources: null
})

// getters
const getters = {
    getResources: ({ resources }) => {
        return resources
    }
}

// actions
const actions = {
    updateResourceFields({ commit }, updateBody) {
        resourceService.setResourceFields(updateBody).then(() => {
            commit('setResourceFields', updateBody)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_RESOURCES'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_RESOURCES'),
                type: 'error'
            })
        })
    },
    exportResourceFields({ commit }, { updateBody, environment }) {
        resourceService.exportResourceFields(updateBody, environment).then(() => {
            commit('setResourceFields', updateBody)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_RESOURCES'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_RESOURCES'),
                type: 'error'
            })
        })
    },
    getResources({ commit }) {
        resourceService.getResources().then(resources => {
            commit('setResources', resources)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_RESOURCES'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setResources(state, resources) {
        state.resources = resources
    },
    setResourceFields(state, updateBody) {
        Object.keys(updateBody).forEach(key => {
            state.resources[key] = updateBody[key]
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}