import localizationService from "@/services/localization"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    localization: []
})

// getters
const getters = {
    getLocalization: ({ localization }) => {
        return localization
    }
}

// actions
const actions = {
    getLocalization({ commit }) {
        localizationService.getLocalization().then(localization => {
            commit('setLocalization', localization)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_LOCALES'),
                type: 'error'
            })
        })
    },
    updateLocalization(state, data) {  
        localizationService.setLocalization(data).then(() => {
            store.dispatch('localization/getLocalization')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_LOCALES'),
                type: 'success'
            })
        }).catch((err) => {
            notify({
                title: i18n.global.t('ERROR'),
                text: err,
                type: 'error'
            })
        })
    },
}

// mutations
const mutations = {
    setLocalization(state, localization) {
        state.localization = localization
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}