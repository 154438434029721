import mockUserService from "@/services/mockUsers"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    mockUsers: []
})

// getters
const getters = {
    getMockUsers: ({ mockUsers }) => {
        return mockUsers
    }
}

// actions
const actions = {
    editMockUser(state, { userId, userBody }) {
        mockUserService.editMockUser(userId, userBody).then(() => {
            store.dispatch("leaderboard/getTrophyLeaderboard")
            store.dispatch("mockUsers/getMockUsers")
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_MOCK_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_MOCK_USER'),
                type: 'error'
            })
        })
    },
    addMockUser(state, userBody) {
        mockUserService.addMockUser(userBody).then(() => {
            store.dispatch("leaderboard/getTrophyLeaderboard")
            store.dispatch("mockUsers/getMockUsers")
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_MOCK_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_MOCK_USER'),
                type: 'error'
            })
        })
    },
    removeMockUser(state, userId) {
        mockUserService.deleteMockUser(userId).then(() => {
            store.dispatch("leaderboard/getTrophyLeaderboard")
            store.dispatch("mockUsers/getMockUsers")
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.REMOVE_MOCK_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.REMOVE_MOCK_USER'),
                type: 'error'
            })
        })
    },
    getMockUsers({ commit }) {
        mockUserService.getMockUsers().then(mockUsers => {
            commit('setMockUsers', mockUsers)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_MOCK_USERS'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setMockUsers(state, mockUsers) {
        state.mockUsers = mockUsers
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}