import newsService from "@/services/news"
import store from "@/store"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    news: []
})

// getters
const getters = {
    getNews: ({ news }) => {
        return news
    }
}

// actions
const actions = {
    getNews({ commit }) {
        newsService.getNews().then(news => {
            commit('setNews', news)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_NEWS'),
                type: 'error'
            })
        })
    },
    updateNews(state, { id, data }) {
        newsService.setNews(id,data).then(() => {
            store.dispatch('news/getNews')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_NEWS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_NEWS'),
                type: 'error'
            })
        })
    },
    exportNews(state, { id, data, environment }) {
        newsService.exportNews(id,data, environment).then(() => {
            store.dispatch('news/getNews')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_NEWS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_NEWS'),
                type: 'error'
            })
        })
    },
    async addNews(state, { data }) {
        return await newsService.addNews(data).then(res => {
            store.dispatch('news/getNews')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_NEWS'),
                type: 'success'
            })
            return res
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_NEWS'),
                type: 'error'
            })
            return null
        })
    },
    deleteNews(state, { id }) {
        newsService.deleteNews(id).then(() => {
            store.dispatch('news/getNews')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_NEWS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_NEWS'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setNews(state, news) {
        state.news = news
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}