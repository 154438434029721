import botDeckService from "@/services/botDeck"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    botDecks: [],
    filters: {}
})

// getters
const getters = {
    getBotDecks: ({ botDecks }) => {
        return botDecks
    },
    getFilters: ({ filters }) => {
        return filters
    },
}

// actions
const actions = {
    getBotDecks({ commit }) {
        botDeckService.getBotDecks().then(botDecks => {
            commit('setBotDecks', botDecks)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_BOT_DECKS'),
                type: 'error'
            })
        })
    },
    async updateBotDeck(state, { id, data }) {
        return botDeckService.setBotDeck(id,data).then(() => {
            store.dispatch('botDecks/getBotDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_BOT_DECK'),
                type: 'success'
            })
            return true;
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_BOT_DECK'),
                type: 'error'
            })
            return false;
        })
    },
    exportBotDeck(state, { id, data, environment }) {
        botDeckService.exportBotDeck(id,data, environment).then(() => {
            store.dispatch('botDecks/getBotDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_BOT_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_BOT_DECK'),
                type: 'error'
            })
        })
    },
    addBotDeck(state, { data }) {
        botDeckService.addBotDeck(data).then(() => {
            store.dispatch('botDecks/getBotDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_BOT_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_BOT_DECK'),
                type: 'error'
            })
        })
    },
    deleteBotDeck(state, { id, index }) {
        botDeckService.deleteBotDeck({ id, index }).then(() => {
            store.dispatch('botDecks/getBotDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_BOT_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_BOT_DECK'),
                type: 'error'
            })
        })
    },
    exportAllBotDecks(state, data) {
        botDeckService.exportAllBotDecks(data).then(() => {
            store.dispatch('botDecks/getBotDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_BOT_DECKS'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_BOT_DECKS'),
                type: 'error'
            })
        })},
    setFilters({ commit }, filters) {
      commit('setFilters', filters);
    }
}

// mutations
const mutations = {
    setBotDecks(state, botDecks) {
        state.botDecks = botDecks;
    },
    setFilters(state, filters) {
        state.filters = filters;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}