import promoCodeService from "@/services/promoCode"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    promoCodes: []
})

// getters
const getters = {
    getPromoCodes: ({ promoCodes }) => {
        return promoCodes
    }
}

// actions
const actions = {
    getPromoCodes({ commit }) {
        promoCodeService.getPromoCodes().then(promoCodes => {
            commit('setPromoCodes', promoCodes)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_PROMO_CODES'),
                type: 'error'
            })
        })
    },
    updatePromoCode(state, data) {  
        console.log(data);
        promoCodeService.setPromoCode(data).then(() => {
            store.dispatch('promoCodes/getPromoCodes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_PROMO_CODE'),
                type: 'success'
            })
        }).catch((err) => {
            notify({
                title: i18n.global.t('ERROR'),
                text: err,
                type: 'error'
            })
        })
    },
    addPromoCode(state, { data }) {
        promoCodeService.addPromoCode(data).then(() => {
            store.dispatch('promoCodes/getPromoCodes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_PROMO_CODE'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_PROMO_CODE'),
                type: 'error'
            })
        })
    },
    deletePromoCode(state, data) {
        promoCodeService.deletePromoCode(data).then(() => {
            store.dispatch('promoCodes/getPromoCodes')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_PROMO_CODE'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_PROMO_CODE'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setPromoCodes(state, promoCodes) {
        state.promoCodes = promoCodes
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}