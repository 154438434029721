import getAxios from './axios'

export default {
    getLocalization: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/localization/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_PROMO_CODES'))
            })
        })
    },
    setLocalization: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/localization/edit', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_PROMO_CODE'))
            })
        })
    },
}