const PERMISSION_DISPATCH_MAP = {
    "config": ["serverConfig/getServerConfig"],
    /*"assets": ["assets/getAssets"],
    "units": ["units/getUnits", "unitsNext/getUnits"],
    "heroes": ["heroes/getHeroes"],
    "bosses": ["bosses/getBosses"],
    "spawner": ["spawners/getSpawner"],
    "news": ["news/getNews", "assets/getAssets"],
    "mails": ["mails/getMails", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "shop": ["shop/getShopContent", "assets/getAssets", "chests/getChests"],
    "promotions": ["promotions/getPromotions", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "specialOffers": ["promotions/getPromotions", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "botDecks": ["botDecks/getBotDecks", "units/getUnits", "heroes/getHeroes"],
    "mirrorDecks": ["mirrorDecks/getMirrorDecks", "units/getUnits", "heroes/getHeroes"],
    "gameModeDecks": ["gameModeDecks/getGameModeDecks", "units/getUnits", "heroes/getHeroes"],
    "wiki": ["wiki/getWiki", "assets/getAssets"],
    "rewards": ["rewards/getRewards", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "tips": ["resources/getResources"],
    "season": ["seasons/getSeasons", "assets/getAssets"],
    "seasonContent": ["seasons/getSeasonPassContent", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "trophyRoad": ["road/getTrophyRoad", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
    "leaderboard": ["leaderboard/getTrophyLeaderboard", "assets/getAssets"],
    "mockUsers": ["mockUsers/getMockUsers"],
    "events": ["events/getEvents", "units/getUnits", "heroes/getHeroes", "chests/getChests", "assets/getAssets"],
    "panelAdmin": ["panelAdmin/getPanelUsers"],
    "promoCodes": ["promoCodes/getPromoCodes"]*/
}

const PAGE_PERMISSION_MAP = {
    "units-next": "units",
    "spawners": "spawner",
    "special-offers" : "specialOffers",
    "bot-decks": "botDecks",
    "mirror-decks": "mirrorDecks",
    "game-mode-decks": "gameModeDecks",
    "tips-tricks": "tips",
    "seasons": "season",
    "season-content": "seasonContent",
    "trophy-road": "trophyRoad",
    "leaderboard-trophy": "leaderboard",
    "mock-users": "mockUsers",
    "panel-admin": "panelAdmin",
    "localization": "localization",
    "game-logs": "gameLogs",
    "promo-codes": "promoCodes",
    "daily-deals": "shop",
    "events-main": "events",
    "quests": "quests",
    "monitor": "monitor",
    "log-report": "monitor",
    "leaderbots": "botDecks",
    "factions": "factions",
    "skills": "factions",
    "faction-config": "factions",
    "dynamic-difficulty-system": "godMode",
    "modifiers": "gameModifiers",
    "coop": "coop"
}

const getAvailableDispatches = customClaims => {
    const res = [];
    if(customClaims.includes('panelAdmin')) {
        Object.values(PERMISSION_DISPATCH_MAP).forEach(addArray => concatUnique(res, addArray));
        return res;
    }
    customClaims.forEach(claim => concatUnique(res, PERMISSION_DISPATCH_MAP[claim]));
    return res;

}

const concatUnique = (array, additionalArray) => {
    (additionalArray || []).forEach(value => {
        if(array.findIndex(x => x === value) < 0) array.push(value);
    })
}

const checkUserPermissionForPage = (page, claims) => {
    if(!claims) return false;
    if(claims.includes('panelAdmin')) return true;
    return claims.includes(PAGE_PERMISSION_MAP[page] || page);
}

module.exports = {
    getAvailableDispatches,
    checkUserPermissionForPage
}