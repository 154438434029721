import panelAdminService from "@/services/panelAdmin"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    panelUsers: []
})

// getters
const getters = {
    getPanelUsers: ({ panelUsers }) => {
        return panelUsers
    }
}

// actions
const actions = {
    getPanelUsers({ commit }) {
        panelAdminService.getPanelUsers().then(panelUsers => {
            commit('setPanelUsers', panelUsers)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_PANEL_USERS'),
                type: 'error'
            })
        })
    },
    updatePanelUserPermissions(state, { uid, permissions }) {
        return panelAdminService.updatePanelUserPermissions(uid, permissions).then(() => {
            store.dispatch('panelAdmin/getPanelUsers');
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_PANEL_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_PANEL_USER'),
                type: 'error'
            })
        })
    },
    createPanelUser(state, userData) {
        return panelAdminService.createPanelUser(userData).then(() => {
            store.dispatch('panelAdmin/getPanelUsers');
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_PANEL_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_PANEL_USER'),
                type: 'error'
            })
        })
    },
    deletePanelUser(state, uid) {
        return panelAdminService.deletePanelUser(uid).then(() => {
            store.dispatch('panelAdmin/getPanelUsers');
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_PANEL_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_PANEL_USER'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setPanelUsers(state, panelUsers) {
        state.panelUsers = panelUsers
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}