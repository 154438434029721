import getAxios from './axios'

export default {
    getUnits: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/unit/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_UNITS'))
            })
        })
    },
    setUnit: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/unit/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_UNIT'))
            })
        })
    },
    setUnitFields: (ids, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/unit/edit-fields', { doc_ids: ids, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_UNIT_FIELDS'))
            })
        })
    },
    exportUnit: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/unit/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_UNIT'))
            })
        })
    },
    deleteUnit: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/unit/delete', { doc_id: id, index }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_UNIT'))
            })
        })
    },
    addUnit: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/unit/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_UNIT'))
            })
        })
    },

}