import getAxios from './axios'


export default {
    setTrophyRoad: trophyRoad => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/road/trophy/edit', trophyRoad).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_TROPHY_ROAD'))
            })
        })
    },
    exportTrophyRoad: (trophy_road, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/road/trophy/export', { trophy_road, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_TROPHY_ROAD'))
            })
        })
    },
    getTrophyRoad: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/road/trophy/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_TROPHY_ROAD'))
            })

        })
    },
    setExperienceRoad: experienceRoad => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                console.log("HERE")
                axios.post( '/api/road/experience/edit', experienceRoad).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_EXPERIENCE_ROAD'))
            })
        })
    },
    exportExperienceRoad: (experienceRoad, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/road/experience/export', { experience_road: experienceRoad, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_EXPERIENCE_ROAD'))
            })
        })
    },
    getExperienceRoad: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/road/experience/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_EXPERIENCE_ROAD'))
            })

        })
    }
}