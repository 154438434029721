// initial state
const state = () => ({
    isLoading: true
})

// getters
const getters = {
    getLoadingStatus: ({isLoading}) => {
        return isLoading;
    },
}

// actions
const actions = {
    loadingStatus({ commit }, status) {
        commit('setLoadingStatus', status);
    }
}

// mutations
const mutations = {
    setLoadingStatus(state, status) {
        state.isLoading = status;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}