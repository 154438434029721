import getAxios from './axios'

export default {
    getMirrorDecks: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/mirror-deck/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_MIRROR_DECKS'))
            })
        })
    },
    setMirrorDeck: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mirror-deck/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_MIRROR_DECK'))
            })
        })
    },
    exportMirrorDeck: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mirror-deck/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_MIRROR_DECK'))
            })
        })
    },
    deleteMirrorDeck: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mirror-deck/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_MIRROR_DECK'))
            })
        })
    },
    addMirrorDeck: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/mirror-deck/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_MIRROR_DECK'))
            })
        })
    },

}