import rewardService from "@/services/reward"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    rewards: []
})

// getters
const getters = {
    getRewards: ({ rewards }) => {
        return rewards
    }
}

// actions
const actions = {
    getRewards({ commit }) {
        rewardService.getRewards().then(rewards => {
            commit('setRewards', rewards)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_REWARDS'),
                type: 'error'
            })
        })
    },
    updateReward(state, { id, data }) {
        rewardService.setReward(id,data).then(() => {
            store.dispatch('rewards/getRewards')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_REWARD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_REWARD'),
                type: 'error'
            })
        })
    },
    exportReward(state, { id, data, environment }) {
        rewardService.exportReward(id,data, environment).then(() => {
            store.dispatch('rewards/getRewards')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_REWARD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_REWARD'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setRewards(state, rewards) {
        state.rewards = rewards
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}