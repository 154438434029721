<template>
  <header class="top-navbar">
    <a class="main-header">{{$t('MAIN_HEADER')}}</a>
    <a class="btn-top-panel" v-if="isLoggedIn" @click="logout">{{$t('SIGN_OUT')}}</a>
  </header>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "TopPanel",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/getIsLoggedIn'])

    return {
      isLoggedIn,
      logout: () => store.dispatch('auth/logout'),
      env: process.env.VUE_APP_ENV
    }
  }
}
</script>

<style scoped>
.top-navbar {
  background-color: #003584!important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  position: sticky;
  z-index: 1020;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 25px;
}



.top-navbar {
  padding: .75rem 1rem;
  font-size: 1rem!important;
  background-color: rgba(0,0,0,0.25);
  box-shadow: inset -1px 0 0 rgba(0 0 0 0.25);
  font-weight: 600;
  cursor: pointer;
}

.btn-top-panel {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  color: white;
  background-color: rgba(0,0,0,0.25);
  box-shadow: inset -1px 0 0 rgba(0 0 0 0.25);
}

</style>